exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-dla-deweloperow-doradztwo-oraz-stworzenie-strategii-marketingowej-i-promocji-projektu-js": () => import("./../../../src/pages/dla-deweloperow/doradztwo-oraz-stworzenie-strategii-marketingowej-i-promocji-projektu.js" /* webpackChunkName: "component---src-pages-dla-deweloperow-doradztwo-oraz-stworzenie-strategii-marketingowej-i-promocji-projektu-js" */),
  "component---src-pages-dla-deweloperow-js": () => import("./../../../src/pages/dla-deweloperow.js" /* webpackChunkName: "component---src-pages-dla-deweloperow-js" */),
  "component---src-pages-dla-deweloperow-zaplanowanie-strategii-sprzedazowej-i-aktywna-sprzedaz-projektow-js": () => import("./../../../src/pages/dla-deweloperow/zaplanowanie-strategii-sprzedazowej-i-aktywna-sprzedaz-projektow.js" /* webpackChunkName: "component---src-pages-dla-deweloperow-zaplanowanie-strategii-sprzedazowej-i-aktywna-sprzedaz-projektow-js" */),
  "component---src-pages-dla-deweloperow-zarzadzanie-i-wykonczenie-apartamentu-w-inwestycji-deweloperskiej-js": () => import("./../../../src/pages/dla-deweloperow/zarzadzanie-i-wykonczenie-apartamentu-w-inwestycji-deweloperskiej.js" /* webpackChunkName: "component---src-pages-dla-deweloperow-zarzadzanie-i-wykonczenie-apartamentu-w-inwestycji-deweloperskiej-js" */),
  "component---src-pages-dla-klientow-doradztwo-podatkowe-js": () => import("./../../../src/pages/dla-klientow/doradztwo-podatkowe.js" /* webpackChunkName: "component---src-pages-dla-klientow-doradztwo-podatkowe-js" */),
  "component---src-pages-dla-klientow-finansowanie-js": () => import("./../../../src/pages/dla-klientow/finansowanie.js" /* webpackChunkName: "component---src-pages-dla-klientow-finansowanie-js" */),
  "component---src-pages-dla-klientow-js": () => import("./../../../src/pages/dla-klientow.js" /* webpackChunkName: "component---src-pages-dla-klientow-js" */),
  "component---src-pages-dla-klientow-zarzadzanie-apartamentem-wykonczenie-wnetrz-js": () => import("./../../../src/pages/dla-klientow/zarzadzanie-apartamentem-wykonczenie-wnetrz.js" /* webpackChunkName: "component---src-pages-dla-klientow-zarzadzanie-apartamentem-wykonczenie-wnetrz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-kontakt-potwierdzenie-widget-js": () => import("./../../../src/pages/kontakt-potwierdzenie-widget.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-widget-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-porownywarka-js": () => import("./../../../src/pages/porownywarka.js" /* webpackChunkName: "component---src-pages-porownywarka-js" */),
  "component---src-pages-portfel-nieruchomosci-inwestycje-miejskie-js": () => import("./../../../src/pages/portfel-nieruchomosci/inwestycje-miejskie.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-inwestycje-miejskie-js" */),
  "component---src-pages-portfel-nieruchomosci-inwestycje-nad-morzem-js": () => import("./../../../src/pages/portfel-nieruchomosci/inwestycje-nad-morzem.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-inwestycje-nad-morzem-js" */),
  "component---src-pages-portfel-nieruchomosci-inwestycje-w-gorach-js": () => import("./../../../src/pages/portfel-nieruchomosci/inwestycje-w-gorach.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-inwestycje-w-gorach-js" */),
  "component---src-pages-portfel-nieruchomosci-inwestycje-zagraniczne-js": () => import("./../../../src/pages/portfel-nieruchomosci/inwestycje-zagraniczne.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-inwestycje-zagraniczne-js" */),
  "component---src-pages-portfel-nieruchomosci-js": () => import("./../../../src/pages/portfel-nieruchomosci.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-js" */),
  "component---src-pages-portfel-nieruchomosci-warmia-i-mazury-js": () => import("./../../../src/pages/portfel-nieruchomosci/warmia-i-mazury.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-warmia-i-mazury-js" */),
  "component---src-pages-portfel-nieruchomosci-zrealizowane-projekty-js": () => import("./../../../src/pages/portfel-nieruchomosci/zrealizowane-projekty.js" /* webpackChunkName: "component---src-pages-portfel-nieruchomosci-zrealizowane-projekty-js" */),
  "component---src-pages-ulubione-js": () => import("./../../../src/pages/ulubione.js" /* webpackChunkName: "component---src-pages-ulubione-js" */),
  "component---src-pages-wyszukiwarka-js": () => import("./../../../src/pages/wyszukiwarka.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-apartment-standalone-js": () => import("./../../../src/templates/apartment-standalone.js" /* webpackChunkName: "component---src-templates-apartment-standalone-js" */),
  "component---src-templates-investment-js": () => import("./../../../src/templates/investment.js" /* webpackChunkName: "component---src-templates-investment-js" */),
  "component---src-templates-investment-thanks-js": () => import("./../../../src/templates/investment-thanks.js" /* webpackChunkName: "component---src-templates-investment-thanks-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

